import React, { useState } from "react";
import { generatePath, Link } from "react-router-dom";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@nef/core";
import styled from "styled-components";
import { useClient, useMutation } from "jsonapi-react";
import { toast } from "react-toastify";

import { ConfirmationModal } from "../modals/ConfirmationModal";
import { DatatableSchema, Vendor } from "../../api/types";
import Toast from "../Toast";
import { PATHS } from "../../routes";

import styles from "./DatatableSchemaRecord.module.scss";
import DatatableSchemaUploadStatusBadge from "./DatatableSchemaUploadStatusBadge";
import EnableDataIngestionButton from "./EnableDataIngestionButton";
import DisableDataIngestionButton from "./DisableDataIngestionButton";

interface DatatableSchemaRecordProps {
  datatable: DatatableSchema;
  vendor?: Vendor;
}

const EditIcon = styled(FontAwesomeIcon)`
  color: #0094bd;
`;

const DatatableSchemaRecord = ({
  datatable,
  vendor
}: DatatableSchemaRecordProps) => {
  const datatableId = `${datatable.vendorCode}/${datatable.code}/${datatable.version.code}`;
  const client = useClient();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editDatatable] = useMutation<DatatableSchema>(
    ["datatable_schema", datatableId],
    { method: "PUT" }
  );

  const vendorLogo = vendor?.profileImage?.thumb?.url;
  const editDatatableRoute = generatePath(PATHS.EDIT_DATATABLE, {
    vendorCode: datatable.vendorCode,
    datatableCode: datatable.code,
    versionCode: datatable.version.code
  });

  const creatorName = `${datatable.creator.firstName || ""} ${
    datatable.creator.lastName || ""
  }`;
  const isDatatablePublished = datatable.version.code === "FINAL";
  const isDatatableBeingUpdated = !!datatable.source?.cronTime;

  const deleteDatatable = async () => {
    if (isDatatablePublished) return;

    setDeleteModalOpen(false);

    const { error } = await client.delete(["datatable_schema", datatableId]);

    if (!error) {
      toast(
        <Toast
          type="success"
          title="Data table is deleted"
          details={[{ message: `${datatable.name} has been deleted` }]}
        />
      );
      return;
    }

    toast(
      <Toast
        type="error"
        title="Error deleting datatable"
        details={[{ message: error.detail }]}
      />
    );
  };

  const disableDataUpload = async () => {
    const requestBody = {
      source: {
        cron_time: null
      }
    };

    const { error } = await editDatatable(requestBody);

    if (error) {
      toast(
        <Toast
          type="error"
          title="Error disabling data upload"
          details={[{ message: error.detail }]}
        />
      );
    }
  };

  const enableDataUpload = async () => {
    const requestBody = {
      source: {
        cron_time: "*/20 * * * *"
      }
    };

    const { error } = await editDatatable(requestBody);

    if (error) {
      toast(
        <Toast
          type="error"
          title="Error enabling data upload"
          details={[{ message: error.detail }]}
        />
      );
    }
  };

  return (
    <>
      <div className={styles.record} data-testid="datatableSchemaRecord">
        <div className={styles.logo}>
          {vendorLogo && (
            <img
              src={vendorLogo}
              alt={vendor.name}
              data-testid="datatableSchemaRecord_logo"
            />
          )}
        </div>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.badge}>
              <DatatableSchemaUploadStatusBadge datatable={datatable} />
            </div>

            <Link
              to={editDatatableRoute}
              data-testid="datatableSchemaRecord_name"
            >
              {datatable.name}
            </Link>

            <div
              className={styles.description}
              data-testid="datatableSchemaRecord_description"
            >
              {datatable.description}
            </div>
          </div>
        </div>
        <div className={styles.info}>
          <div data-testid="datatableSchemaRecord_createdAt">
            Created At {format(datatable.createdAt, "d/M/yyyy hh:mm a")}
          </div>

          <div
            data-testid="datatableSchemaRecord_lastSavedAt"
            className={styles["last-saved-at"]}
          >
            Last Saved At {format(datatable.updatedAt, "d/M/yyyy hh:mm a")}
          </div>
        </div>
        <div
          className={styles.info}
          data-testid="datatableSchemaRecord_createdBy"
        >
          Created by ({creatorName})
        </div>
        <div className={styles.actions}>
          <Link
            to={editDatatableRoute}
            className={styles.action}
            data-testid="datatableSchemaRecord_edit"
          >
            <EditIcon iconClassName="fa-edit" iconSetClassName="far" />
          </Link>
          {!isDatatablePublished && (
            <button
              type="button"
              className={`${styles.delete} ${styles.action}`}
              onClick={() => setDeleteModalOpen(true)}
              data-testid="datatableSchemaRecord_delete"
            >
              <FontAwesomeIcon
                iconSetClassName="far"
                iconClassName="fa-trash-alt"
              />
            </button>
          )}

          {isDatatablePublished && (
            <>
              {isDatatableBeingUpdated ? (
                <DisableDataIngestionButton onConfirm={disableDataUpload} />
              ) : (
                <EnableDataIngestionButton onConfirm={enableDataUpload} />
              )}
            </>
          )}
        </div>
      </div>

      <ConfirmationModal
        isOpen={deleteModalOpen}
        question="Are you sure you want to delete this datatable? This action cannot be undone."
        onConfirm={() => deleteDatatable()}
        onDismiss={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default DatatableSchemaRecord;
