import React, { useState } from "react";
import {
  FlexGrid,
  FormField,
  FormSelect,
  FormRadioCheckboxGroup,
  FormRadioCheckboxButton
} from "@nef/core";
import { CustomChangeEvent } from "@nef/core/lib/components/FormInput";
import { Controller } from "react-hook-form";
import { union } from "lodash";

import {
  BaseProductCategory,
  ProductFormMode,
  SdgIndicator,
  ProductCategory,
  SupportingDocument,
  StringMap
} from "../../api/types";
import { useProductContext } from "../../hooks/useProductContext";
import FormLabel from "../common/FormLabel";
import extractFormSelectOnChangeValue from "../../utils/nef-utils";
import LabelValueWithHint from "../common/LabelValueWithHint";
import MoreInfoTooltip from "../MoreInfoTooltip";
import CapSurveyFormFieldRow from "../cap-survey-form/CapSurveyFormFieldRow";
import TOOLTIP from "../cap-survey-form/tooltips";
import useDefaultAuth from "../../hooks/useDefaultAuth";

import SdgIndicatorsInput from "./components/SdgIndicatorsInput";
import styles from "./ProductMetadataStep.module.scss";
import { HISTORY_OPTIONS } from "./dropdown-options";
import ProductCategoriesInput from "./components/ProductCategoriesInput";
import FrequenciesInput from "./components/FrequenciesInput";
import {
  PRODUCT_CONTACT_SALES_EMAIL_INFO,
  PRODUCT_COVERAGE_INFO,
  PRODUCT_DOCUMENTATION_INFO,
  PRODUCT_REPORTING_LAG_INFO,
  PRODUCT_SAMPLE_INFO
} from "./hints";
import {
  CAP_DATA_FREQUENCY_OPTIONS,
  CAP_DELIVERY_FREQUENCY_OPTIONS
} from "./frequency-options";
import MarkdownEditor from "./MarkdownEditor";
import SupportingDocsUploader from "./components/uploader/SupportingDocsUploader";

interface ProductMetadataStepProps {
  productSupportingDocs: SupportingDocument[];
  sdgIndicators: SdgIndicator[];
  prodCategories?: ProductCategory[];
  productCategoriesError: StringMap | undefined;
  isProductCategoriesLoading: boolean | undefined;
  coreProductCategories?: ProductCategory[];
  errorCoreCategories: StringMap | undefined;
  isLoadingCoreCategories: boolean | undefined;
}

const ProductMetadataStep = ({
  productSupportingDocs,
  sdgIndicators,
  prodCategories,
  productCategoriesError,
  isProductCategoriesLoading,
  coreProductCategories,
  isLoadingCoreCategories,
  errorCoreCategories
}: ProductMetadataStepProps) => {
  const [supportingDocs, setSupportingDocs] = useState<SupportingDocument[]>(
    productSupportingDocs
  );
  const { product, productForm } = useProductContext(ProductFormMode.EDIT);
  const { getValues, setValue, control, watch } = productForm;

  const { authenticatedUser } = useDefaultAuth();

  const isManagerAdmin =
    authenticatedUser?.rolesArray?.includes("manager_admin");

  const hasCapProductMeta = !!product?.capProductMetadatum;

  const history = watch("history");

  const handleHistoryOnChange = (
    onChange: (...event: any[]) => void,
    formSelectOnChangeParam: any
  ) => {
    const newValue = extractFormSelectOnChangeValue(formSelectOnChangeParam);
    onChange(newValue);

    if (newValue !== "since") {
      setValue("since", "", {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const getSelectedCategories = (category: BaseProductCategory) => {
    const currentCategories = getValues("productCategories");

    const newCategories = [...currentCategories];

    const categoryIndex = newCategories.findIndex(
      newCategory => newCategory.id === category.id
    );

    if (categoryIndex === -1) {
      newCategories.push(category);
    } else {
      newCategories.splice(categoryIndex, 1);
    }

    return newCategories;
  };

  const getSelectedFrequencies = (
    field: "deliveryFrequency" | "dataFrequency",
    e: CustomChangeEvent
  ) => {
    const currentValues = getValues(field);

    const target = e.target as HTMLInputElement;

    if (e.checked) {
      return [...currentValues, target.value];
    }

    return currentValues.filter((v: string) => v !== target.value);
  };

  const getSelectedIndicators = (code: string) => {
    const newSdgIndicators = getValues("productMetadatum.sdgIndicators") || [];
    const codeIndex = newSdgIndicators.findIndex(
      (indicator: string) => indicator === code
    );
    if (codeIndex > -1) {
      newSdgIndicators.splice(codeIndex, 1);
    } else {
      newSdgIndicators.push(code);
    }

    return newSdgIndicators;
  };

  const mapDescriptionToValue = (description: string | null) => {
    return description === null ? "" : description;
  };

  return (
    <form className={styles.form}>
      <FlexGrid.Row>
        <FlexGrid.Column xs={24} md={12}>
          <Controller
            name="deliveryFrequency"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FrequenciesInput
                label="Delivery Frequencies"
                name={name}
                value={value}
                optional={true}
                handleChange={e =>
                  onChange(getSelectedFrequencies("deliveryFrequency", e))
                }
                options={CAP_DELIVERY_FREQUENCY_OPTIONS}
                data-testid="productDetailForm_deliveryFrequencies"
              />
            )}
          />
        </FlexGrid.Column>
        <FlexGrid.Column xs={24} md={12}>
          <Controller
            name="dataFrequency"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FrequenciesInput
                label="Data Frequencies"
                name={name}
                value={value}
                optional={true}
                handleChange={e =>
                  onChange(getSelectedFrequencies("dataFrequency", e))
                }
                options={CAP_DATA_FREQUENCY_OPTIONS}
              />
            )}
          />
        </FlexGrid.Column>
      </FlexGrid.Row>
      <FlexGrid.Row>
        <FlexGrid.Column xs={24}>
          <Controller
            name="sample"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormRadioCheckboxGroup
                id="ProductSample"
                type="radio"
                label={
                  (
                    <LabelValueWithHint
                      label="Sample Data Available"
                      hint={PRODUCT_SAMPLE_INFO}
                    />
                  ) as any
                }
                name={name}
                onChange={e => onChange(e.value === "true")}
                optional={true}
                data-testid="productDetailForm_sample"
                value={value?.toString() || false}
              >
                <FormRadioCheckboxButton
                  label="No"
                  value="false"
                  data-testid="productDetailForm_sample_false"
                />
                <FormRadioCheckboxButton
                  label="Yes"
                  value="true"
                  data-testid="productDetailForm_sample_true"
                />
              </FormRadioCheckboxGroup>
            )}
          />
        </FlexGrid.Column>
      </FlexGrid.Row>
      <FlexGrid.Row>
        <FlexGrid.Column xs={24} md={12}>
          <div data-testid="productDetailForm_history">
            <Controller
              name="history"
              control={control}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <FormSelect
                    id="ProductHistory"
                    name={name}
                    label="History"
                    value={HISTORY_OPTIONS.find(o => o.value === value) || null}
                    placeholder="History"
                    options={HISTORY_OPTIONS}
                    optional={true}
                    onChange={(formSelectOnChangeParam: any) => {
                      handleHistoryOnChange(onChange, formSelectOnChangeParam);
                    }}
                    classNamePrefix="history"
                  />
                );
              }}
            />
          </div>
        </FlexGrid.Column>
        {history === "since" && (
          <FlexGrid.Column xs={24} md={12}>
            <Controller
              name="since"
              control={control}
              render={({ field: { onChange, name, value } }) => (
                <FormField
                  type="date"
                  id="ProductSince"
                  name={name}
                  label="Since"
                  value={value}
                  placeholder="Since"
                  optional={true}
                  onChange={onChange}
                  data-testid="productDetailForm_since"
                />
              )}
            />
          </FlexGrid.Column>
        )}
      </FlexGrid.Row>
      <FlexGrid.Row>
        <FlexGrid.Column xs={24} md={12}>
          <Controller
            name="coverage"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <FormField
                id="ProductCoverage"
                name={name}
                label={
                  (
                    <LabelValueWithHint
                      label="Coverage"
                      hint={PRODUCT_COVERAGE_INFO}
                    />
                  ) as any
                }
                value={value}
                placeholder="Coverage"
                optional={true}
                onChange={onChange}
                data-testid="productDetailForm_coverage"
              />
            )}
          />
        </FlexGrid.Column>
        <FlexGrid.Column xs={24} md={12}>
          <Controller
            name="reportingLag"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <FormField
                id="ProductReportingLag"
                name={name}
                label={
                  (
                    <LabelValueWithHint
                      label="Reporting Lag"
                      hint={PRODUCT_REPORTING_LAG_INFO}
                    />
                  ) as any
                }
                value={value}
                placeholder="Reporting Lag"
                optional={true}
                onChange={onChange}
                data-testid="productDetailForm_reportingLag"
              />
            )}
          />
        </FlexGrid.Column>
      </FlexGrid.Row>
      <FlexGrid.Row>
        <FlexGrid.Column xs={24}>
          <Controller
            name="gisSalesTeamEmail"
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <FormField
                type="email"
                id="ProductContactSalesEmail"
                name={name}
                label={
                  (
                    <LabelValueWithHint
                      label="Contact Sales Email"
                      hint={PRODUCT_CONTACT_SALES_EMAIL_INFO}
                    />
                  ) as any
                }
                value={value}
                placeholder="Contact Sales Email"
                optional={false}
                onChange={onChange}
                data-testid="productDetailForm_contactSalesEmail"
              />
            )}
          />
        </FlexGrid.Column>
      </FlexGrid.Row>
      {isManagerAdmin && (
        <>
          <FlexGrid.Row>
            <FlexGrid.Column xs={24}>
              <CapSurveyFormFieldRow tooltip={TOOLTIP.SEARCH_TAGS}>
                <Controller
                  name="searchTags"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <FormField
                        id="searchTags"
                        name={name}
                        type="text"
                        label="Search Tags"
                        optional={true}
                        value={mapDescriptionToValue(value)}
                        onChange={onChange}
                        data-testid="productDetailForm_searchTags"
                      />
                    );
                  }}
                />
              </CapSurveyFormFieldRow>
            </FlexGrid.Column>
          </FlexGrid.Row>
          <FlexGrid.Row>
            <FlexGrid.Column xs={24}>
              <CapSurveyFormFieldRow tooltip={TOOLTIP.RANK}>
                <Controller
                  name="productMetadatum.rank"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <FormField
                        id="rank"
                        name={name}
                        type="number"
                        label="Rank"
                        optional={true}
                        value={mapDescriptionToValue(value?.toString())}
                        onChange={onChange}
                        data-testid="productDetailForm_rank"
                      />
                    );
                  }}
                />
              </CapSurveyFormFieldRow>
            </FlexGrid.Column>
          </FlexGrid.Row>
        </>
      )}
      <FlexGrid.Row>
        <FlexGrid.Column xs={24}>
          <Controller
            name="productCategories"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <ProductCategoriesInput
                  value={value}
                  handleChange={category =>
                    onChange(getSelectedCategories(category))
                  }
                  productType="CORE"
                  prodCategories={coreProductCategories}
                  productCategoriesError={errorCoreCategories}
                  isProductCategoriesLoading={isLoadingCoreCategories}
                />
                {product.productType === "ADP" && (
                  <ProductCategoriesInput
                    productType="ADP"
                    value={value}
                    isGridLayout={false}
                    mode="EDITOR"
                    handleChange={category =>
                      onChange(getSelectedCategories(category))
                    }
                    prodCategories={prodCategories}
                    productCategoriesError={productCategoriesError}
                    isProductCategoriesLoading={isProductCategoriesLoading}
                  />
                )}
                {product.productType === "ESG" && (
                  <ProductCategoriesInput
                    productType="ESG"
                    value={value}
                    isGridLayout={false}
                    mode="EDITOR"
                    handleChange={category =>
                      onChange(getSelectedCategories(category))
                    }
                    prodCategories={prodCategories}
                    productCategoriesError={productCategoriesError}
                    isProductCategoriesLoading={isProductCategoriesLoading}
                  />
                )}
              </>
            )}
          />
        </FlexGrid.Column>
      </FlexGrid.Row>
      {product.productType === "ESG" && (
        <>
          <FlexGrid.Row>
            <FlexGrid.Column xs={24}>
              <Controller
                name="productMetadatum.sdgIndicators"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <SdgIndicatorsInput
                      values={value}
                      sdgIndicators={sdgIndicators}
                      handleChange={selectedIndicator =>
                        onChange(getSelectedIndicators(selectedIndicator))
                      }
                    />
                  );
                }}
              />
            </FlexGrid.Column>
          </FlexGrid.Row>
          <FlexGrid.Row>
            <FlexGrid.Column xs={24}>
              <Controller
                name="productMetadatum.frameworkRegulationNotes"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormField
                      id="frameworkRegulationNotes"
                      name={name}
                      type="text"
                      label="Frameworks or Regulations"
                      optional={true}
                      value={mapDescriptionToValue(value)}
                      onChange={onChange}
                      data-testid="esgMetadataForm_frameworkRegulationNotes"
                    />
                  );
                }}
              />
            </FlexGrid.Column>
          </FlexGrid.Row>
        </>
      )}
      {isManagerAdmin && (
        <FlexGrid.Row>
          <FlexGrid.Column xs={24}>
            <CapSurveyFormFieldRow tooltip={TOOLTIP.RECOMMENDED_PRODUCT_CODES}>
              <Controller
                name="recommendedProductCodes"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <FormField
                      id="recommendedProductCodes"
                      name={name}
                      type="text"
                      label="Recommended Product Codes"
                      optional={true}
                      value={mapDescriptionToValue(value)}
                      onChange={onChange}
                      data-testid="productDetailForm_recommendedProductCodes"
                    />
                  );
                }}
              />
            </CapSurveyFormFieldRow>
          </FlexGrid.Column>
        </FlexGrid.Row>
      )}

      {hasCapProductMeta && (
        <FlexGrid.Row>
          <FlexGrid.Column xs={24}>
            <CapSurveyFormFieldRow tooltip={TOOLTIP.TECHNICAL_CONTACT}>
              <Controller
                name="capProductMetadatum.technicalContactName"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormField
                    id="capProductMetadatum.technicalContactName"
                    name={name}
                    type="text"
                    label="Technical Contact Name"
                    placeholder="Technical Contact name"
                    value={value || ""}
                    optional={true}
                    onChange={onChange}
                    data-testid="productDetailForm_technicalContactName"
                  />
                )}
              />

              <Controller
                name="capProductMetadatum.technicalContactEmail"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <FormField
                    id="capProductMetadatum.technicalContactEmail"
                    name={name}
                    type="email"
                    label="Technical Contact Email"
                    placeholder="Technical Contact Email"
                    value={value || ""}
                    optional={true}
                    onChange={onChange}
                    data-testid="productDetailForm_technicalContactEmail"
                  />
                )}
              />
            </CapSurveyFormFieldRow>
          </FlexGrid.Column>
        </FlexGrid.Row>
      )}
      <FlexGrid.Row>
        <FlexGrid.Column xs={24}>
          <CapSurveyFormFieldRow tooltip={TOOLTIP.ADDITIONAL_MATERIALS}>
            <SupportingDocsUploader
              productId={product.id}
              label="Additional Materials"
              supportingDocs={supportingDocs || []}
              testid="additional-material-upload-field"
              onSuccess={v => {
                setSupportingDocs(previous => union([v], previous || []));
              }}
              onDeleteFile={id => {
                if (!supportingDocs) return;
                setSupportingDocs(previous =>
                  previous.filter(doc => doc.id !== id)
                );
              }}
            />
          </CapSurveyFormFieldRow>
        </FlexGrid.Column>
      </FlexGrid.Row>

      <FlexGrid.Row>
        <FlexGrid.Column xs={24}>
          <FormLabel>
            Documentation{" "}
            <MoreInfoTooltip description={PRODUCT_DOCUMENTATION_INFO} />{" "}
            <a
              href="http://www.markdownguide.org/cheat-sheet/"
              target="_blank"
              rel="noreferrer"
              data-testid="productDetailForm_markdownGuide"
            >
              Link to Markdown Guide
            </a>
          </FormLabel>
          <MarkdownEditor />
        </FlexGrid.Column>
      </FlexGrid.Row>
    </form>
  );
};

export default ProductMetadataStep;
