import React from "react";
import {
  FormField,
  FormRadioCheckboxButton,
  FormRadioCheckboxGroup
} from "@nef/core";
import {
  Controller,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm
} from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import CapSurveyFormFieldRow from "../cap-survey-form/CapSurveyFormFieldRow";
import { CapProductToEdit, DatatablesNameDescription } from "../../api/types";
import { extractErrors } from "../../utils/react-hook-form-utils";
import Toast from "../Toast";
import IntakeUploader from "../cap-survey-form/components/IntakeUploader";
import TOOLTIP from "../cap-survey-form/tooltips";
import { buildHostedDatatableSchema } from "../cap-survey-form/schema";
import useCapProductContext from "../../hooks/useCapProductContext";
import { useQueue } from "../../contexts/queue";

import IntakeDatatableModalFooter from "./IntakeDatatableModalFooter";
import { BaseDatatableStepProps } from "./types";
import SchemaUploader from "./components/SchemaUploader";

const HostedDatatableWizardStep1 = ({
  datatable,
  save,
  cancel,
  discard,
  fields,
  existedCodes
}: BaseDatatableStepProps) => {
  const { product: contextProduct } = useCapProductContext();
  const product = contextProduct as CapProductToEdit;

  const datatableSchema = buildHostedDatatableSchema(existedCodes);
  const stepSchema = datatableSchema.pickNested(fields);
  const form = useForm<DatatablesNameDescription>({
    defaultValues: datatable,
    resolver: yupResolver(stepSchema)
  });
  const SAMPLE_FILE_PATH = `/products/${product.id}/${datatable.id}/sample_files/`;
  const {
    handleSubmit,
    control,
    formState: { isSubmitSuccessful, isSubmitting, isDirty }
  } = form;

  const saveStep = async () => {
    await handleSubmit(
      async formData => onHandleSubmit(formData),
      onHandleSubmitError
    )().catch(() => {});
  };

  const onHandleSubmit: SubmitHandler<
    DatatablesNameDescription
  > = async formData => {
    await save(formData, false);
  };

  const onHandleSubmitError: SubmitErrorHandler<
    DatatablesNameDescription
  > = errors => {
    const flatErrors = Object.values(errors);
    const errorDetails = extractErrors(flatErrors).flat();

    toast(
      <Toast
        type="error"
        title="Progress cannot be saved"
        details={errorDetails}
      />
    );
  };
  const { enqueue } = useQueue();

  const deleteSampleFileDelay = (
    filesOnConfirm: string[],
    filesOnCancel: string[]
  ) => {
    if (filesOnConfirm.length !== 0) {
      filesOnConfirm.forEach(file => {
        const filename = SAMPLE_FILE_PATH + file;
        enqueue("delete-sample-files-save", filename, filename);
      });
    }
    if (filesOnCancel.length !== 0) {
      filesOnCancel.forEach(file => {
        const filename = SAMPLE_FILE_PATH + file;
        enqueue("delete-sample-files-discard", filename, filename);
      });
    }
  };

  return (
    <>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.TABLE_NAME_DESCRIPTION}>
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              id="Table Name"
              name={name}
              label="Table Name"
              placeholder="Table Name"
              optional={false}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.TABLE_CODE}>
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              id="Table Description"
              name={name}
              label="Table Description"
              placeholder="Table Description"
              optional={false}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>
      <CapSurveyFormFieldRow tooltip={TOOLTIP.TABLE_CODE}>
        <Controller
          name="code"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              id="Table code"
              name={name}
              label="Table Code"
              placeholder="Table Code"
              optional={false}
              value={value}
              onChange={onChange}
            />
          )}
        />
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip="Does this table have a primary key?">
        <Controller
          name="schemaRequiresPrimaryKeys"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <FormRadioCheckboxGroup
              id="SchemaRequiresPrimaryKeys"
              type="radio"
              label="Does this table have a primary key?"
              name={name}
              value={typeof value === "boolean" ? value.toString() : "true"}
              optional={false}
              onChange={e => onChange(e.value === "true")}
            >
              <FormRadioCheckboxButton label="Yes" value="true" />
              <FormRadioCheckboxButton label="No" value="false" />
            </FormRadioCheckboxGroup>
          )}
        />
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip={TOOLTIP.DATA_DICTIONARY_NO_LINK}>
        <FormProvider {...form}>
          <SchemaUploader
            productId={product.id}
            datatableId={datatable.id}
            vendorId={product.vendor.id}
          />
        </FormProvider>
      </CapSurveyFormFieldRow>

      <CapSurveyFormFieldRow tooltip={TOOLTIP.UPLOAD_SAMPLE_DATA}>
        <Controller
          name="sampleDataUrl"
          control={control}
          render={({ field: { onChange, value } }) => (
            <IntakeUploader
              label="Sample Data Upload"
              path={SAMPLE_FILE_PATH}
              vendorId={product.vendor.id}
              testid="sample-data-upload-field"
              value={value}
              maxFiles={1}
              onSuccess={filenames => {
                deleteSampleFileDelay(value, filenames);
                onChange(filenames);
              }}
              required
              onDeleteFile={filename => {
                const updatedValue = value.filter(v => v !== filename);
                deleteSampleFileDelay([filename], []);
                onChange(updatedValue);
              }}
              accept={{ "text/csv": [".csv"] }}
            />
          )}
        />
      </CapSurveyFormFieldRow>

      <IntakeDatatableModalFooter
        save={saveStep}
        canAdvance={!isSubmitting && isSubmitSuccessful}
        cancel={isDirty ? discard : cancel}
      />
    </>
  );
};

export default HostedDatatableWizardStep1;
