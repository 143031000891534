import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import * as yup from "yup";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { debounce } from "lodash";
import { CircleButton, FontAwesomeIcon, FormSelect, Switch } from "@nef/core";

import { formSchema } from "./schema";
import ProductCard from "./product-card";
import ProductAccess from "./product-access";
import styles from "./product-search.module.scss";

import { OrganizationTeamInvoice, Product, SalesManager } from "./index";

const DEFAULT_PRODUCT = {
  subscriptionType: "trial",
  accessStart: null,
  accessEnd: null,
  salesRepresentative: "",
  notes: "",
  businessContacts: [],
  technicalContacts: [],
  welcomeEmailCc: []
};

function ProductSearch({
  products = [],
  salesManagers,
  existingInvoice,
  inputProductValue,
  setInputProductValue,
  isProductAccess
}: {
  products: Product[];
  salesManagers: SalesManager[];
  existingInvoice: OrganizationTeamInvoice;
  inputProductValue: string;
  setInputProductValue: Dispatch<SetStateAction<string>>;
  isProductAccess?: boolean;
}) {
  const { control, setValue, getValues, watch } =
    useFormContext<yup.Asserts<typeof formSchema>>();

  const { newProductKeys } = getValues();

  const singleProductControl = watch("singleProductControl");

  const { remove, replace } = useFieldArray({
    control,
    name: "newProducts"
  });

  const { remove: removeKey } = useFieldArray({
    control,
    name: "newProductKeys"
  });

  const handleDebounceInput = debounce(
    value => setInputProductValue(value),
    300
  );

  const existingProductMap = useMemo(() => {
    const result: Record<string, any> = {};
    products.forEach((product: any) => {
      let key = "";
      if (product.productType === "DatatableCollection") {
        key = `table-${product.productId}-${product.productCode}`;
      }
      result[key] = product;
    });
    return result;
  }, [products]);

  useEffect(() => {
    if (singleProductControl) {
      replace([DEFAULT_PRODUCT]);
    } else {
      replace(newProductKeys.map(() => DEFAULT_PRODUCT));
    }
  }, [singleProductControl]);

  return (
    <>
      <div
        className={styles["search-container"]}
        data-testid="ProductSearch-Container"
      >
        {!existingInvoice && (
          <div data-testid="OrganizationsAddProductDrawer_select_playwright">
            <FormSelect
              data-testid="OrganizationsAddProductDrawer_select"
              label="Products"
              options={
                inputProductValue
                  ? products
                      ?.filter((product: any) => {
                        return (
                          !existingProductMap[product.key] &&
                          !newProductKeys.some(
                            (user: { value: { id: any } }) =>
                              user.value.id === product.id
                          )
                        );
                      })
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((product: any) => ({
                        label: `${product.name} | ${product.code}`,
                        value: product
                      }))
                  : []
              }
              value={newProductKeys.map((product: any) => ({
                label: product.label,
                value: product.value
              }))}
              onChange={({
                action: actionObject,
                value: internalValue
              }: any) => {
                if (actionObject.action === "remove-value") {
                  const removedIndex = newProductKeys.findIndex((keys: any) => {
                    return keys.value.id === actionObject.removedValue.value.id;
                  });

                  remove(removedIndex);
                  removeKey(removedIndex);
                } else {
                  setValue("newProductKeys", internalValue);
                }
              }}
              placeholder="Select..."
              isMulti={true}
              onInputChange={value => {
                handleDebounceInput(value);
                return value;
              }}
              noOptionsMessage={() =>
                inputProductValue
                  ? "No options found"
                  : "Start typing to search"
              }
              classNamePrefix="productSearchSelect"
            />
          </div>
        )}
        {!existingInvoice && (
          <Controller
            name="singleProductControl"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={!value}
                optional={true}
                label=""
                helpText="Edit Products Individually"
                onChange={() => {
                  onChange(!value);
                }}
                className={styles.checkbox}
                data-testid="OrganizationsAddProductDrawer_checkbox"
              />
            )}
          />
        )}
      </div>
      {(newProductKeys?.length !== 0 || existingInvoice) &&
        (isProductAccess ? (
          <>
            <ProductAccess
              formFieldName="newProducts.0.productAccess"
              confirmToRemove
            />
          </>
        ) : (
          <>
            {singleProductControl || existingInvoice ? (
              <div key="All New Product(s)">
                <ProductCard
                  formName="newProducts.0"
                  title="All New Product(s)"
                  salesManagers={salesManagers}
                  existingInvoice={existingInvoice}
                  datatables={existingInvoice?.datatableCodes}
                />
              </div>
            ) : (
              newProductKeys.map(({ value: product }: any, index: number) => (
                <div key={`${product.id}-${singleProductControl}`}>
                  <ProductCard
                    key={product.id}
                    formName={`newProducts.${index}`}
                    title={`${product.name} | ${product.code}`}
                    salesManagers={salesManagers}
                    datatables={product.datatables}
                  >
                    <CircleButton
                      ghost={true}
                      onClick={() => {
                        remove(index);
                        removeKey(index);
                      }}
                    >
                      <FontAwesomeIcon
                        iconClassName="fa-times"
                        iconSetClassName="fas"
                      />
                    </CircleButton>
                  </ProductCard>
                </div>
              ))
            )}
          </>
        ))}
    </>
  );
}

export default ProductSearch;
